import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import TermsandConditions from './TermsandConditions';
import React from "react";
import { Col, Row } from "react-bootstrap";
import FooterLogo from "../assets/images/sarvadharmafooter.png";
import EmailLogo from "../assets/images/email-24.png";


function ContactUs() {

  const [showTandC, setShowTandC] = useState(false);

  const handleCloseTandC = () => setShowTandC(false);
  const handleShowTandC = () => setShowTandC(true);

  const commonStyles = {
    text: {
      color: "#2E2F35",
      fontSize: window.innerWidth <= 767 ? "12px" : "16px",
      fontSyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      textDecoration: "none",
    },
  };
  const contactStyles = {
    text: {
      color: "#2E2F35",
      fontSize: window.innerWidth <= 767 ? "12px" : "16px",
      fontSyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textDecoration: "none",
    },
  };

  return (
    <Row className="align-items-center" style={{ height: "250px" }}>
      <Col
        md={{ span: 3, order: 1 }}
        sm={{ span: 12, order: 3 }}
        xs={{ span: 12, order: 3 }}
      >
        <Row
          style={{
            paddingTop: window.innerWidth <= 768 ? "10px" : "0",
            paddingBottom: window.innerWidth <= 768 ? "10px" : "0",
          }}
        >
          <Col
            md="6"
            sm="12"
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "right" }}
          >
            <a
              href="https://facebook.com/ssssoindia/"
              target="_blank"
              rel="noopener noreferrer"
            >
             <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="24px" height="24px">    <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"/></svg>
            </a>
            <a
              href="https://twitter.com/ssssoindia?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="24px" height="24px"><path d="M44.719,10.305C44.424,10,43.97,9.913,43.583,10.091l-0.164,0.075c-0.139,0.064-0.278,0.128-0.418,0.191	c0.407-0.649,0.73-1.343,0.953-2.061c0.124-0.396-0.011-0.829-0.339-1.085c-0.328-0.256-0.78-0.283-1.135-0.066	c-1.141,0.693-2.237,1.192-3.37,1.54C37.4,7.026,35.071,6,32.5,6c-5.247,0-9.5,4.253-9.5,9.5c0,0.005,0,0.203,0,0.5l-0.999-0.08	c-9.723-1.15-12.491-7.69-12.606-7.972c-0.186-0.47-0.596-0.813-1.091-0.916C7.81,6.927,7.297,7.082,6.939,7.439	C6.741,7.638,5,9.48,5,13c0,2.508,1.118,4.542,2.565,6.124c-0.674-0.411-1.067-0.744-1.077-0.753	c-0.461-0.402-1.121-0.486-1.669-0.208c-0.546,0.279-0.868,0.862-0.813,1.473c0.019,0.211,0.445,4.213,5.068,7.235l-0.843,0.153	c-0.511,0.093-0.938,0.444-1.128,0.928C6.914,28.437,6.988,28.984,7.3,29.4c0.105,0.141,2.058,2.68,6.299,4.14	C11.334,34.295,8.222,35,4.5,35c-0.588,0-1.123,0.344-1.366,0.88c-0.244,0.536-0.151,1.165,0.237,1.607	C3.532,37.672,7.435,42,17.5,42C34.213,42,42,26.485,42,16v-0.5c0-0.148-0.016-0.293-0.022-0.439	c2.092-2.022,2.879-3.539,2.917-3.614C45.084,11.067,45.014,10.609,44.719,10.305z"/></svg>
            </a>
            <a
              href="https://www.youtube.com/c/SriSathyaSaiSevaOrganisationsIndia/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
              
                <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z" />
              </svg>
            </a>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: window.innerWidth <= 768 ? "10px" : "0",
            paddingBottom: window.innerWidth <= 768 ? "10px" : "0",
          }}
        >
          <Col
            md="3"
            sm="12"
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "right" }}
          ></Col>
          <Col
            md="6"
            sm="12"
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "left" }}
          >
            <p className="mb-2 mt-2" style={contactStyles.text}>
              Contacts
            </p>
            
          </Col>
        </Row>

        <Row
          style={{
            paddingTop: window.innerWidth <= 768 ? "10px" : "0",
            paddingBottom: window.innerWidth <= 768 ? "10px" : "0",
          }}
        >
          <Col
            md="3"
            sm="12"
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "right" }}
          ></Col>
          <Col
            md="2"
            sm="12"
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "left" }}
          >
            <p className="mb-2" style={commonStyles.text}>liquidlove@ssssoindia.org</p>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: window.innerWidth <= 768 ? "10px" : "0",
            paddingBottom: window.innerWidth <= 768 ? "10px" : "0",
          }}
        >
          <Col
            md="3"
            sm="12"
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "left" }}
          ></Col>
          <Col
            style={{ textAlign: window.innerWidth <= 768 ? "center" : "left" }}
          >
            {/*<p onClick={handleShowTandC} style={commonStyles.text}>Terms & Conditions</p>*/}
            <p className="mb-4 mt-2" style={commonStyles.text}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33325 6.09135C1.33325 9.33335 4.01325 11.0607 5.97459 12.6073C6.66658 13.1527 7.33325 13.6667 7.99992 13.6667C8.66658 13.6667 9.33325 13.1533 10.0253 12.6067C11.9873 11.0613 14.6666 9.33335 14.6666 6.09202C14.6666 2.85002 10.9999 0.550683 7.99992 3.66802C4.99992 0.550017 1.33325 2.84935 1.33325 6.09135Z"
                  fill="#DE1717"
                />
              </svg>{" "}
              Life Saved by ssssoindia.org
            </p>
          </Col>
        </Row>

        <Col
          md="12"
          sm="12"
          xs="12"
          style={{ display: window.innerWidth <= 768 ? "block" : "none" }}
        >
          <svg
            width="100%"
            height="10"
            viewBox="0 0 1440 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 5H1440" stroke="#D30000" stroke-width="10" />
          </svg>
        </Col>
      </Col>
      <Col
        md={{ span: 6, order: 2 }}
        sm={{
          span: 12,
          order: 1,
          "&p": {
            fontSize: "14px !important",
          },
        }}
        xs={{ span: 12, order: 1 }}
        style={{ textAlign: "center" }}
      >
        <img
          src={FooterLogo}
          alt="ssssoindia.org"
          style={{ width: "136px", height: "134px" }}
        />
        <p
          style={{
            color: "#000",
            textAlign: "center",
            fontSize: window.innerWidth <= 767 ? "14px" : "33px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            width: "334px",
            margin: "0 auto",
          }}
        >
          SRI SATHYA SAI SEVA ORGANISATIONS, INDIA
        </p>
      </Col>
      <Col
        md={{ span: 3, order: 3 }}
        sm={{ span: 12, order: 2 }}
        xs={{ span: 12, order: 2 }}
        style={{
          textAlign: window.innerWidth <= 768 ? "center" : "left",
          marginTop: window.innerWidth <= 768 ? "18px" : "0",
          p: {
            marginBottom: "10px",
          },
        }}
      >
        <p
          style={{
            color: "#2E2F35",
            marginBottom: window.innerWidth <= 767 ? "0" : "11px",
            fontSize: window.innerWidth <= 767 ? "14px" : "18px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          Our other websites
        </p>
        <p style={{ marginBottom: window.innerWidth <= 767 ? "0" : "11px" }}>
          <a href="https://www.ssssoindia.org" style={commonStyles.text}>
            www.ssssoindia.org
          </a>
        </p>

        <p style={{ marginBottom: window.innerWidth <= 767 ? "0" : "11px" }}>
          <a href="https://www.srisathyasai.org" style={commonStyles.text}>
            www.srisathyasai.org
          </a>
        </p>
        <p style={{ marginBottom: window.innerWidth <= 767 ? "0" : "11px" }}>
          <a href="https://www.sssbalvikas.in" style={commonStyles.text}>
            www.sssbalvikas.in
          </a>
        </p>
      </Col>
      <Modal show={showTandC} onHide={handleCloseTandC}>
        <Modal.Header>
          <Modal.Title className='form_header' style={{fontSize:"20px"}} >Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TermsandConditions></TermsandConditions>
        </Modal.Body>
        
      </Modal>
    </Row>
  );
}

export default ContactUs;
