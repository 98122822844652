export const DONOR_SUBMIT = 'DONOR_SUBMIT';
export const DONOR_SUBMIT_SUCCESS = 'DONOR_SUBMIT_SUCCESS';
export const DONOR_SUBMIT_ERROR = 'DONOR_SUBMIT_ERROR';

export const DONOR_UPDATE = 'DONOR_UPDATE';
export const DONOR_UPDATE_SUCCESS = 'DONOR_UPDATE_SUCCESS';
export const DONOR_UPDATE_ERROR = 'DONOR_UPDATE_ERROR';

export const DONOR_SELFSERVICE = 'DONOR_SELFSERVICE';
export const DONOR_SELFSERVICE_SUCCESS = 'DONOR_SELFSERVICE_SUCCESS';
export const DONOR_SELFSERVICE_ERROR = 'DONOR_SELFSERVICE_ERROR';

export const DONOR_FETCHBYPHONE = 'DONOR_FETCHBYPHONE';
export const DONOR_FETCHBYPHONE_SUCCESS = 'DONOR_FETCHBYPHONE_SUCCESS';
export const DONOR_FETCHBYPHONE_ERROR = 'DONOR_FETCHBYPHONE_ERROR';

export const PATIENT_SUBMIT = 'PATIENT_SUBMIT';
export const PATIENT_SUBMIT_SUCCESS = 'PATIENT_SUBMIT_SUCCESS';
export const PATIENT_SUBMIT_ERROR = 'PATIENT_SUBMIT_ERROR';

export const SEND_OTP_DONOR = 'SEND_OTP_DONOR';
export const SEND_OTP_DONOR_SUCCESS = 'SEND_OTP_DONOR_SUCCESS';
export const SEND_OTP_DONOR_ERROR = 'SEND_OTP_DONOR_ERROR';

export const LOCALITY_FETCH = 'LOCALITY_FETCH';
export const LOCALITY_FETCH_SUCCESS = 'LOCALITY_FETCH_SUCCESS';
export const LOCALITY_FETCH_ERROR = 'LOCALITY_FETCH_ERROR';