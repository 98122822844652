import React from "react";
import "./style.css"; // Adjust the path to your CSS file

const TermsandConditions = () => {

  return (
    <div>
      To be drafted for Terms and Conditions
      <br></br>      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

    </div>
  );
};

export default TermsandConditions;
