import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselImage1 from "../assets/images/carouselImage1.png";
import CarouselImage2 from "../assets/images/carouselImage2.png";
import CarouselImage3 from "../assets/images/carouselImage3.png";

import CarouselImage5 from "../assets/images/carouselImage5.jpg";
import CarouselImage6 from "../assets/images/carouselImage6.jpg";

const ImageGallery = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    document.title = 'Liquid Love';
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
  };

  const slidesData = [
    {
      id: 1,
      img: CarouselImage1,
    },
    {
      id: 2,
      img: CarouselImage5,
    },
    {
      id: 3,
      img: CarouselImage6,
    },
  ];

  return (
    
    <div className="slider-wrapper">
      <Slider
        {...settingsMain}
        asNavFor={nav2}
        ref={(slider) => setSlider1(slider)}
      >
        {slidesData.map((slide) => (
          <div className="slick-slide" key={slide.id}>
            <img
              className="slick-slide-image"
              src={`${slide.img}`}
              alt="slider"
            />
          </div>
        ))}
      </Slider>
      {/* <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsThumbs}
          asNavFor={nav1}
          ref={(slider) => setSlider2(slider)}
        >
          {slidesData.map((slide) => (
            <div className="slick-slide" key={slide.id}>
              <img
                className="slick-slide-image"
                src={`${slide.img}`}
                alt="thumbnail"
              />
            </div>
          ))}
        </Slider>

        <div className="banner_slogan_belowCarouseal center_alignment">
          <br />
        <p className="banner_from_your_veins" style={{ color:"#FFF" }}>
              "From your veins to another's, the bridge between life and hope, is
              built with drops of Liquid Love."
            </p>
        </div>
        
      </div> */}
    </div>
  );
};
export default ImageGallery;
