export const fetchLocalityService = (request) => {
  const FETCH_LOCALITY_API_ENDPOINT = '/service/liquidlove/localitymaster/country/' + encodeURIComponent(request.locality.country) + '/state/' + encodeURIComponent(request.locality.stateLocation) + '/district/' + encodeURIComponent(request.locality.district);
  //const FETCH_LOCALITY_API_ENDPOINT = 'http://localhost:4001/service/liquidlove/localitymaster/country/' + request.locality.country + '/state/' + request.locality.stateLocation + '/district/' + request.locality.district;
  
  const parameters = {
    method: 'GET',
    headers: {      
      'Authorization': 'Bearer ' + request.locality.token,
      'Content-Type': 'application/json'
    },
  };

  return fetch(FETCH_LOCALITY_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};