import * as types from '../actions';

export const initialState={
  requestId: '',
  id: '',
  fullname: '',
  age: undefined,
  gender: 'male',
  bloodGroup: 'O+',
  units: undefined,
  requiredBy: '',
  purpose: '',
  hospitalName: '',
  hospital: '',
  attender: '',
  email: '',
  phone: '',
  district: '',
  pincode: '',
  stateLocation: '',
  country: '',
  status: '',
  matchingDonors: undefined,
  matchDonorPhone: undefined,
  matchingDonorsDetails: [],
  actualDonors: [],
  auditTrail: [],
  systemRemarks: '',
  noServiceResponse: false,
  locality: '',
  action: '',
  feedback: '',
  lastModifiedBy: '',
  lastModifiedAt: '',
  notes: ''
};

export default function(state = initialState, action) {
  let response = '';
  
  switch(action.type) {
    case types.PATIENT_SUBMIT_SUCCESS:
      response = action.response;
      return { 
        ...state,
        action: action.type,
        fullname: response.fullname,
        age: response.age,
        gender: response.gender,
        bloodGroup: response.bloodGroup,
        units: response.units,
        requiredBy: response.requiredBy,
        purpose: response.purpose,        
        hospitalName: response.hospitalName,
        hospital: response.hospital,
        attender: response.attender,
        email: response.email,
        phone: response.phone,
        district: response.district,
        pincode: response.pincode,
        stateLocation: response.stateLocation,
        status: response.status,
        country: response.country,
        volunteerDonors : response.volunteerDonors,
        actualDonors : response.actualDonors,
        auditTrail : response.auditTrail,
        systemRemarks: 'Sairam! Updated successfully',
        noUpdateServiceResponse: false,
        locality: response.locality,
        feedback: response.feedback,
        notes: response.notes,
        lastModifiedBy: response.modifiedBy,
        lastModifiedAt: response.modifiedTimestamp,
        response
       };
    case types.PATIENT_SUBMIT_ERROR:
      response = action.response;
      return { 
        ...state, 
        noServiceResponse: true,
        response };
    default:
      return state;
  }
};