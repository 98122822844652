import { put, call } from 'redux-saga/effects';
import { fetchLocalityService } from '../services/localityService';

import * as types from '../actions'

export function* fetchLocalitySaga(payload) {
  try {
    const response = yield call(fetchLocalityService, payload);
    yield put({ type: types.LOCALITY_FETCH_SUCCESS, response });
  } catch(error) {
    yield put({ type: types.LOCALITY_FETCH_ERROR, error })
  }
}