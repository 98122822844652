export const patientService = (request) => {
  
  const PATIENT_API_ENDPOINT = '/service/liquidlove/patients';
  //const PATIENT_API_ENDPOINT = 'http://localhost:4001/service/liquidlove/patients';
  //const PATIENT_API_ENDPOINT = 'http://72.167.42.194:8081/service/liquidlove/patients';
  
  const parameters = {
    method: 'POST',
    credentials: 'include',
    headers: {      
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request.patient.data)
  };

  return fetch(PATIENT_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};