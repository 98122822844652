import React from 'react';
import { Col, Row } from 'react-bootstrap';
import NoteIcon from '../assets/images/note.svg';
import CallIcon from '../assets/images/call.svg';
import groupIcon from '../assets/images/group.svg';
import locationIcon from '../assets/images/location.svg';

function Works() {
  const commonStyles = {
    text: {
      color: '#2E2F35',
      fontSize: '16px',
      fontSyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      textDecoration: 'none',
    }
  };

  const works = {
    label: {
      color: '#2E2F35',
      fontFamily: 'Abril Fatface',
      fontWeight: 400,
      fontFeatureSettings: "'clig' off, 'liga' off;",
    },
    card_text: {
      color: '#DE1717',
      fontFamily: 'Rubik',
      // fontSize: '140%',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    layout: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto'
    },
    card_style: {
      borderRadius: '10px',
      height: '180px',
      flexShrink: 0,
      boxShadow: '20px 20px 40px 0px rgba(255, 225, 225, 0.10)',
      backGround: 'var(--white-900, #FFF)',
    },
    icon: {
      width: '36px',
      height: '36px',
      flexShrink: 0,
    },
    rounded_badge: {
      position: 'relative',
      display: 'inline-block',
    },
    badge: {
      width: '10%',
      zIndex: '1',
      position: 'relative',
      textAlign: 'center',
      top: '15px',
      right: '-45%',
      backgroundColor: '#DE1717',
      color: 'white',
      borderRadius: '50%',
      padding: '8px',
      fontSize: '15px',
      fontWeight: 'bold'
    }
  }
  return (
    <div className='backdrop'
      style={works.layout}
    >
      <div className='row'>
        <h3 className='works_head' style={works.label}>How It Works</h3>
        <div className='row works_card_row' style={{padding:'3%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Row style={{paddingBottom:'3%'}}>
            <Col sm={{span: 4, offset: 1 }} style={works.card_style}>
              <div style={works.badge} >1</div>
              <div className='col'>
                <div className='card' style={{ padding: '3%' }}>
                  <img src={NoteIcon} style={works.icon} alt="SVG Image" />
                  <h4 className='Works_Card_head' style={works.card_text}> Submit Request For Blood </h4>
                  <p className='works_card_text'>
                    Request for blood submitted by the Patient's attender
                  </p>
                </div>
              </div>
            </Col>
            
            <Col sm={{ span: 4, offset: 2 }} style={works.card_style}>
              <div style={works.badge} >2</div>
              <div className='col'>
                <div className='card' style={{ padding: '3%' }}>
                  <img src={CallIcon} style={works.icon} alt="SVG Image" />
                  <h4 className='Works_Card_head' style={works.card_text} > Request Gets Verified </h4>
                  <p className='works_card_text'>
                    Request is verified by our volunteers through a call</p>
                </div>
              </div>
            </Col>
          </Row>
          
          <Row >
            <Col sm={{span: 4, offset: 1 }} style={works.card_style}>
              <div style={works.badge} >3</div>
              <div className='col'>
                <div className='card' style={{ padding: '3%' }}>
                  <img src={groupIcon} style={works.icon} alt="SVG Image" />
                  <h4 className='Works_Card_head' style={works.card_text}> Volunteers Contact Donors </h4>
                  <p className='works_card_text'>
                    Eligible blood donors are contacted by team of our volunteers
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={{ span: 4, offset: 2 }} style={works.card_style}>
              <div style={works.badge} >4</div>
              <div className='col'>
                <div className='card' style={{ padding: '3%' }}>
                  <img src={locationIcon} style={works.icon} alt="SVG Image" />
                  <h4 className='Works_Card_head' style={works.card_text} >Donor Donates Blood</h4>
                  <p className='works_card_text'>
                    Donor contacts the patient’s attender and donates blood at the required place.</p>
                </div>
              </div>
            </Col>
          </Row>
          <br/>
          <br/>
        </div>
      </div>
    </div>

  );
}

export default Works;
