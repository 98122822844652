import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import configureStore from "./store/configureStore";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from "./views/App.js";
import PatientRegistration from "./views/PatientRegistration.js";
import DonorRegistration from "./views/DonorRegistration.js";

const store = configureStore();
const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={(props) => <App {...props} />} />
        <Route path="/liquidlove" Component={(props) => <App {...props} />} />
        <Route
          path="/liquidlove/patient"
          Component={(props) => <PatientRegistration {...props} />}
        />
        <Route
          path="/liquidlove/donorRegistration"
          Component={(props) => <DonorRegistration {...props} />}
        />
        <Route
          path="/liquidlove/donor"
          Component={(props) => <DonorRegistration {...props} />}
        />
      </Routes>
    </BrowserRouter>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
