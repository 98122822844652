import * as types from '../actions';

export const initialState={
  noServiceResponse: false,
  findByPhone: false,
  isDonorVerified: false,
  accessMode: ''
}

export default function(state = [], action) {
  let response = '';
  let sendOTPDonor = undefined;
  let donorUpdateOTP = undefined;

  switch(action.type) {
    case types.DONOR_SUBMIT_SUCCESS:
      response = action.response;
      return { 
        ...state, 
        noServiceResponse: false,
        response };  
    case types.DONOR_SUBMIT_ERROR:
      response = action.response;
      return { 
        ...state, 
        noServiceResponse: true,
        response };
    case types.DONOR_UPDATE_SUCCESS:
      response = action.response;
      return { 
        ...state, 
        noServiceResponse: false,
        response };  
    case types.DONOR_UPDATE_ERROR:
      response = action.response;
      return { 
        ...state, 
        noServiceResponse: true,
        response };
    case types.DONOR_SELFSERVICE_SUCCESS:
      response = action.response;
      return { 
        ...state, 
        noServiceResponse: false,
        response };  
    case types.DONOR_SELFSERVICE_ERROR:
      response = action.response;
      return { 
        ...state, 
        noServiceResponse: true,
        response };        
    case types.SEND_OTP_DONOR_SUCCESS:
      sendOTPDonor = action.response;
      return { 
        ...state, 
        noServiceResponse: false,
        sendOTPDonor };
    case types.SEND_OTP_DONOR_ERROR:
      sendOTPDonor = action.response;
      return { 
        ...state, 
        noServiceResponse: true,
        sendOTPDonor };                                  
    default:
      return state;
  }
};