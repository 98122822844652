import * as types from '../actions';

export const initialState={
}

export default function(state = initialState, action) {
  const response = action.response;

  switch(action.type) {
    case types.LOCALITY_FETCH_SUCCESS:        
      return {...state, response };
    case types.LOCALITY_FETCH_ERROR:
      return { ...state, response };
    default:
      return state;
  }
};