import { put, call } from 'redux-saga/effects';
import { 
  patientService
} from '../services/patientService';

import * as types from '../actions'

export function* patientSaga(payload) {
  try {
    const response = yield call(patientService, payload);
    yield put({ type: types.PATIENT_SUBMIT_SUCCESS, response, });
  } catch(error) {
    yield put({ type: types.PATIENT_SUBMIT_ERROR, error })
  }
}