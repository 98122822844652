import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
// reactstrap components
import { Row, Col, FormGroup } from 'react-bootstrap';
// core components
import { donorFormSubmitAction, selfserviceDonorAction } from '../actions/donorActions';

const moment = require('moment');

const commonStyles = {
  input: {
    padding: '14px',
    fontSize: '18px',
    display: 'block',
    alignItems: 'center',
    borderRadius: '10px',
    border: '1px solid #D9D9D9',
    backgroundImage: 'none',
  },
  tittle: {
    color: '#DE1717',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Abril Fatface',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '110%', /* 44px */
  },
  label: {
    color: '#2E2F35',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
};

class DonorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstFocus: false,
      lastFocus: false,
      fullName: '',
      dateOfBirth: '',
      gender: '',
      bloodGroup: 'O+',
      email: '',
      phone: '',
      alternatePhone: '',
      lastDonatedOn: '',
      pincode: '',
      accessMode: '',
      availableAfter: '',
      show: false,
      isSuccess: false,
      modalOKEnabled: false,
      navigateToHome: false,
    };
    this.setShow = this.setShow.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  }

  componentDidMount() {
    document.title = 'Liquid Love Donor';
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    loadCaptchaEnginge(6);
  }

  componentWillMount() {
    //check if the mode is admin
    this.state.show = false;
    this.state.isSuccess = false;
    this.state.modalOKEnabled = false;

    this.state.accessMode = this.props.response.donor.accessMode;
    this.props.response.donor.accessMode = '';
  }

  componentWillUnmount() {
    document.body.classList.remove('landing-page');
    document.body.classList.remove('sidebar-collapse');

    if (this.props.response.address !== 'undefined') this.props.response.address = '';

    if (this.props.response.donor.response !== 'undefined') delete this.props.response.donor.response;

    this.props.response.donor.noServiceResponse = false;
    this.props.response.donor.accessMode = '';
  }

  setShow(bool) {
    this.setState({ show: bool });
  }

  handleClose = () => {
    this.setShow(false);
    if (this.state.isSuccess) this.state.navigateToHome = true;
  };

  handleShow = () => {
    this.setShow(true);
  };

  render() {
    if (this.state.navigateToHome) {
      this.state.navigateToHome = false;
      return <Navigate to="/liquidlove" replace={true} />
    }

    let message;
    let donorData = '';
    message = 'Sairam! Processing your request';

    if (this.state.accessMode === 'self-service' &&
      this.props.response.hasOwnProperty('donor') && this.props.response.donor.hasOwnProperty('donorUpdateOTP')) {
      //Populate donor details in this form based on donor fetched by DonorLogin after OTP verification
      if (!this.props.response.donor.isDonorVerified)
        message = 'Donor is not logged-in'
      else
        donorData = this.props.response.donor.donorUpdateOTP.donor;
      delete this.props.response.donor.donorUpdateOTP;
    }
    else if (this.props.response.hasOwnProperty('donor') && this.props.response.donor.hasOwnProperty('response')) {
      //New donor registration scenario or self service donor update scenario
      this.state.modalOKEnabled = true;
      if (this.props.response.donor.response !== undefined)
        if (this.props.response.donor.response.hasOwnProperty('error'))
          if (this.props.response.donor.response.message.includes('donor_phone_key'))
            message = 'Sairam! Provided phone is already registered as donor.';
          else if (this.props.response.donor.response.status === 401 && this.state.accessMode === 'admin')
            message = 'Your session has expired. Please login again';
          else if (this.props.response.donor.response.status === 401 && this.state.accessMode === 'self-service')
            message = 'Invalid or expired OTP. Click Donor->Update profile menu to resend OTP';
          else message = this.props.response.donor.response.message;
        else if (
          this.props.response.donor.response.cause !== undefined &&
          this.props.response.donor.response.cause.cause !== undefined &&
          this.props.response.donor.response.cause.cause.message !== undefined &&
          this.props.response.donor.response.cause.cause.message.includes(
            'ERROR: duplicate key value violates unique constraint'
          )
        )
          message = 'Sairam! Provided phone is already registered as donor.';
        else {
          this.state.isSuccess = true;
          donorData = this.props.response.donor.response;
          message = 'Sairam! Your details are saved.';
        }
      else if (this.props.response.donor.noServiceResponse)
        message = 'Sairam. Sorry! We are not able to save your details. Please try later.';
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          fullName: donorData !== '' ? donorData.fullname : '',
          dateOfBirth: donorData !== '' ? moment(donorData.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
          gender: donorData !== '' ? donorData.gender : 'male',
          bloodGroup: donorData !== '' ? donorData.bloodGroup : 'O+',
          email: donorData !== '' ? donorData.email : '',
          phone: donorData !== '' ? donorData.phone : '',
          address: donorData !== '' ? donorData.address : '',
          pincode: donorData !== '' ? donorData.pincode : '',
          lastDonatedOn: donorData !== '' && donorData.lastDonatedOn ? moment(donorData.lastDonatedOn, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
          ailments: donorData !== '' ? donorData.ailments : [],
          height: donorData !== '' && donorData.height ? donorData.height : '',
          weight: donorData !== '' && donorData.weight ? donorData.weight : '',
          alternatePhone: donorData !== '' && donorData.alternatePhone ? donorData.alternatePhone : '',
          availableAfter: donorData !== '' && donorData.availableAfter ? moment(donorData.availableAfter, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
          language: donorData !== '' ? donorData.language : '',
          userCaptcha: '',
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required('Required'),
          dateOfBirth: Yup.string()
            .required('Required')
            .test('dobval0', 'Date format DD-MM-YYYY', (value) => {
              const formattedDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
              return moment(formattedDate, 'DD/MM/YYYY', true).isValid();
            })
            .test('dobval1', 'Age must be between 18 and 60', (value) => {
              const formattedDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
              return moment().diff(moment(formattedDate, 'DD/MM/YYYY'), 'years', true) >= 18;
            })
            .test('dobval2', 'age must be between 18 and 60', (value) => {
              const formattedDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
              return moment().diff(moment(formattedDate, 'DD/MM/YYYY'), 'years', true) <= 60;
            }),
          email: Yup.string().required('Required').email('email is invalid'),
          address: Yup.string().required('Required'),
          phone: Yup.string()
            .required('Required')
            .matches(
              /^[1-9]+[0-9]*$/,
              'Please provide a valid mobile number with only numbers and without preceding zero'
            )
            .length(10, 'Please enter 10 digits without any spaces'),
          height: Yup.number().test('hpos', 'please provide valid height in cms', (val) => {
            return val !== undefined && val !== null && val !== '' ? val > 0 : true;
          }),
          weight: Yup.number().test('wpos', 'please provide valid weight in Kgs', (val) => {
            return val !== undefined && val !== null && val !== '' ? val > 0 : true;
          }),
          pincode: Yup.string()
            .required('Required')
            .matches(/^[1-9]+[0-9]*$/, 'Please provide a valid pincode with only numbers and without preceding zero')
            .length(6, 'Please enter 6 digits without any spaces'),
          lastDonatedOn: Yup.string()
            .test('ldval0', 'Date format DD-MM-YYYY', (value) => {
              if (value !== undefined && value !== null && value !== '' && value !== 'Invalid date' && value !== 'DD-MM-YYYY' && value !== 'YYYY-MM-DD') {
                const formattedDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
                return moment(formattedDate, 'DD/MM/YYYY', true).isValid();
              } else {
                return true;
              }
            })
            .test('ldval1', 'Future date!!', (value) => {
              if (value !== undefined && value !== null && value !== 'Invalid date' && value !== '') {
                const formattedDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
                return moment().diff(moment(formattedDate, 'DD/MM/YYYY'), 'days', true) >= 0;
              } else {
                return true;
              }
            }),
          availableAfter: Yup.string().test('ldval2', 'Date format DD-MM-YYYY', (value) => {
            if (value !== undefined && value !== null && value !== '' && value !== 'Invalid date' && value !== 'DD-MM-YYYY' && value !== 'YYYY-MM-DD') {
              const formattedDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
              return moment(formattedDate, 'DD/MM/YYYY', true).isValid();
            } else {
              return true;
            }
          }),
          userCaptcha: Yup.string().test('userCaptcha', 'Invalid captcha', (value) => {
            return value && validateCaptcha(value, false);
          }),
        })}
        onSubmit={(fields) => {
          let id = donorData !== '' ? donorData.id : undefined;
          let fullname = fields.fullName;
          let dateOfBirth = moment(fields.dateOfBirth, 'YYYY-MM-DD', true).format('DD-MM-YYYY');
          let gender = fields.gender;
          let email = fields.email;
          let bloodGroup = fields.bloodGroup;
          let phone = fields.phone;
          let lastDonatedOn = moment(fields.lastDonatedOn, 'YYYY-MM-DD', true).isValid() ? moment(fields.lastDonatedOn, 'YYYY-MM-DD', true).format('DD-MM-YYYY') : '';
          let address = fields.address;
          let pincode = fields.pincode;
          let height = fields.height;
          let weight = fields.weight;
          let alternatePhone = fields.alternatePhone;
          let availableAfter = moment(fields.availableAfter, 'YYYY-MM-DD', true).isValid() ? moment(fields.availableAfter, 'YYYY-MM-DD', true).format('DD-MM-YYYY') : '01-01-2021';
          let createTimestamp = donorData.createTimestamp;
          let modifiedTimestamp = donorData.modifiedTimestamp;
          let modifiedBy = donorData.modifiedBy;
          let language = fields.language;
          let otp = donorData !== '' ? donorData.otp : '';
          let otpExpiresIn = donorData.otpExpiresIn;
          let officeAddress = '';
          let officePhone = '';
          let isActive = donorData !== '' ? donorData.isActive : 'Y';
          let ailments = fields.ailments;

          const data = {
            id,
            fullname,
            dateOfBirth,
            gender,
            bloodGroup,
            email,
            phone,
            lastDonatedOn,
            ailments,
            address,
            pincode,
            height,
            weight,
            alternatePhone,
            availableAfter,
            createTimestamp,
            modifiedTimestamp,
            modifiedBy,
            language,
            otp,
            otpExpiresIn,
            officeAddress,
            officePhone,
            isActive,
          };

          const payload = {
            data: data,
          };
          message = 'Processing your request';
          this.handleShow();
          if (this.state.accessMode === 'self-service') this.props.dispatch(selfserviceDonorAction(payload));
          else {
            this.props.dispatch(donorFormSubmitAction(data));
          }
        }}
        render={({ errors, touched }) => (
          <div className='card container form_background' style={{ borderRadius: '30px' }}>
            <h3 className='form_header'>Sign up</h3>
            <Form>
              <Modal
                centered
                show={this.state.show}
                onHide={this.handleClose}
                backdrop='static'
                keyboard={false}
              >
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                  <button
                    disabled={!this.state.modalOKEnabled}
                    className='btn btn-dark'
                    onClick={this.handleClose}
                  >
                    OK
                  </button>
                </Modal.Footer>
              </Modal>
              <FormGroup>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='name'
                      style={commonStyles.label}
                    >
                      Full Name
                    </label>
                    <Field
                      name='fullName'
                      type='text'
                      placeholder='Enter Name'
                      className={'form-control' + (errors.fullName && touched.fullName ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='fullName'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='dateOfBirth'
                      style={commonStyles.label}
                    >
                      DOB
                    </label>
                    <Field
                      name='dateOfBirth'
                      type="date"
                      format="DD-MM-YYYY"
                      className={'form-control' + (errors.dateOfBirth && touched.dateOfBirth ? ' is-invalid' : '')}
                      style={{
                        ...commonStyles.input,
                        textTransform: 'uppercase',
                      }}
                    />
                    <ErrorMessage
                      name='dateOfBirth'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='gender'
                      style={commonStyles.label}
                    >
                      Gender
                    </label>
                    <Field
                      class='form-control'
                      name='gender'
                      id='gender'
                      component='select'
                      style={commonStyles.input}
                    >
                      <option>Male</option>
                      <option>Female</option>
                    </Field>
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='bloodgroup'
                      style={commonStyles.label}
                    >
                      Blood Group
                    </label>
                    <Field
                      class='form-control'
                      name='bloodGroup'
                      id='bloodGroup'
                      component='select'
                      style={commonStyles.input}
                    >
                      <option>O+</option>
                      <option>O-</option>
                      <option>A+</option>
                      <option>A-</option>
                      <option>B+</option>
                      <option>B-</option>
                      <option>AB+</option>
                      <option>AB-</option>
                      <option>A1+</option>
                      <option>A1-</option>
                      <option>A1B+</option>
                      <option>A1B-</option>
                      <option>A2+</option>
                      <option>A2-</option>
                      <option>A2B+</option>
                      <option>A2B-</option>
                      <option>Hh</option>
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='phone'
                      style={commonStyles.label}
                    >
                      Mobile
                    </label>
                    <Field
                      name='phone'
                      placeholder='Enter Number'
                      type='text'
                      className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='phone'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='email'
                      style={commonStyles.label}
                    >
                      Email
                    </label>
                    <Field
                      name='email'
                      placeholder='Enter email'
                      type='text'
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='address'
                      style={commonStyles.label}
                    >
                      Present Address
                    </label>
                    <Field
                      name='address'
                      placeholder='Enter Address'
                      type='text'
                      className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='address'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='pincode'
                      style={commonStyles.label}
                    >
                      Pincode
                    </label>
                    <Field
                      name='pincode'
                      placeholder='Enter Code'
                      type='text'
                      className={'form-control' + (errors.pincode && touched.pincode ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='pincode'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='lastDonatedOn'
                      style={commonStyles.label}
                    >
                      Last Donated
                    </label>

                    <Field
                      name='lastDonatedOn'
                      type='date'
                      format="DD-MM-YYYY"
                      className={'form-control' + (errors.lastDonatedOn && touched.lastDonatedOn ? ' is-invalid' : '')}
                      style={{
                        ...commonStyles.input,
                        textTransform: 'uppercase',
                      }}
                    />
                    <ErrorMessage
                      name='lastDonatedOn'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 3, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='ailments'
                      style={commonStyles.label}
                    >
                      Ailments
                    </label><br />
                    <label check
                      for='ailments'
                    >
                      <Field
                        id="diabetic"
                        name="ailments"
                        type="checkbox"
                        value="diabetic"></Field>
                      Diabetic
                    </label><br />
                    <label check
                      for='ailments'
                    >
                      <Field
                        id="hypertension"
                        name="ailments"
                        type="checkbox"
                        value="hypertension"></Field>
                      Hypertension
                    </label><br />
                    <label check
                      for='ailments'
                    >
                      <Field
                        id="others"
                        name="ailments"
                        type="checkbox"
                        value="others"></Field>
                      Others
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <Row>
                      <Col
                        md='6'
                        sm='12'
                        xs='12'
                      >
                        <label
                          for='height'
                          style={commonStyles.label}
                        >
                          Height (Cm)
                        </label>
                        <Field
                          name='height'
                          type='text'
                          className={'form-control' + (errors.height && touched.height ? ' is-invalid' : '')}
                          style={commonStyles.input}
                        />
                        <ErrorMessage
                          name='height'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Col>
                      <Col
                        md='6'
                        sm='12'
                        xs='12'
                      >
                        <label
                          for='weight'
                          style={commonStyles.label}
                        >
                          Weight (Kg)
                        </label>
                        <Field
                          name='weight'
                          type='text'
                          className={'form-control' + (errors.weight && touched.weight ? ' is-invalid' : '')}
                          style={commonStyles.input}
                        />
                        <ErrorMessage
                          name='weight'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='availableAfter'
                      style={commonStyles.label}
                    >
                      Available after
                    </label>
                    <Field
                      name='availableAfter'
                      type='date'
                      format="DD-MM-YYYY"
                      className={
                        'form-control' + (errors.availableAfter && touched.availableAfter ? ' is-invalid' : '')
                      }
                      style={{
                        ...commonStyles.input,
                        textTransform: 'uppercase',
                      }}
                    />
                    <ErrorMessage
                      name='availableAfter'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row className='align-items-center'>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='language'
                      style={commonStyles.label}
                    >
                      Communication languages
                    </label>
                    <Field
                      name='language'
                      type='text'
                      className={'form-control' + (errors.language && touched.language ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='language'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4 loadDivStyle'
                  >
                    <LoadCanvasTemplate reloadText='Reload' />
                    <Field
                      name='userCaptcha'
                      placeholder='Enter Captcha'
                      type='text'
                      className={'form-control' + (errors.userCaptcha && touched.userCaptcha ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='userCaptcha'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
              </FormGroup>
              <div className='buttons'>
                <button
                  type='reset'
                  className='btn btn-dark'
                  style={{ width: "200px", height: "50px" }}
                >
                  CANCEL
                </button>
                <button
                  type='submit'
                  className='btn btn-danger'
                  style={{ width: "200px", height: "50px" }}
                >
                  SUBMIT
                </button>
              </div>
            </Form>
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(DonorForm);
