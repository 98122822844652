export const donorService = (request) => {
  
  //const DONOR_API_ENDPOINT = 'http://72.167.42.194:8081/service/liquidlove/donors';
  const DONOR_API_ENDPOINT = '/service/liquidlove/donors';
  //const DONOR_API_ENDPOINT = 'http://localhost:4001/service/liquidlove/donors';

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request.donor)
  };

  return fetch(DONOR_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const updateDonorService = (request) => {
  
  //const UPDATE_DONOR_API_ENDPOINT = 'http://72.167.42.194:8081/service/liquidlove/updateDonor';
  const UPDATE_DONOR_API_ENDPOINT = '/service/liquidlove/updateDonor';
  //const UPDATE_DONOR_API_ENDPOINT = 'http://localhost:4001/service/liquidlove/updateDonor';

  const parameters = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + request.donor.token,      
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request.donor.data)
  };

  return fetch(UPDATE_DONOR_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const selfserviceDonorService = (request) => {
  
  //const SELFSERVICE_DONOR_API_ENDPOINT = 'http://72.167.42.194:8081/service/liquidlove/donor/selfservice';
  const SELFSERVICE_DONOR_API_ENDPOINT = '/service/liquidlove/donor/selfservice';
  //const SELFSERVICE_DONOR_API_ENDPOINT = 'http://localhost:4001/service/liquidlove/donor/selfservice';

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request.donor.data)
  };

  return fetch(SELFSERVICE_DONOR_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};


export const fetchDonorByPhoneService = (request) => {
  
  //const FETCHDONORBYPHONE_API_ENDPOINT = 'http://72.167.42.194:8081/service/liquidlove/donors/findbyphone?size=' + request.payload.size + '&page=' + request.payload.pageNum + '&phone=' + encodeURIComponent(request.payload.phone);
  const FETCHDONORBYPHONE_API_ENDPOINT = '/service/liquidlove/donors/findbyphone?size=' + request.payload.size + '&page=' + request.payload.pageNum + '&phone=' + encodeURIComponent(request.payload.phone);
  //const FETCHDONORBYPHONE_API_ENDPOINT = 'http://localhost:4001/service/liquidlove/donors/findbyphone?size=' + request.payload.size + '&page=' + request.payload.pageNum + '&phone=' + encodeURIComponent(request.payload.phone);
    
  const parameters = {
    method: 'GET',
    headers: {      
      'Authorization': 'Bearer ' + request.payload.token,
      'Content-Type': 'application/json'
    },    
  };

  return fetch(FETCHDONORBYPHONE_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

  export const sendOTPDonorService = (request) => {

    //const SENDOTPDONOR_API_ENDPOINT = 'http://72.167.42.194:8081/service/liquidlove/donors/' + request.payload.phone + '/send-otp';
    const SENDOTPDONOR_API_ENDPOINT = '/service/liquidlove/donors/' + request.payload.phone + '/send-otp';
    //const SENDOTPDONOR_API_ENDPOINT = 'http://localhost:4001/service/liquidlove/donors/' + request.payload.phone + '/send-otp';
    const parameters = {
      method: 'POST'
    };

    return fetch(SENDOTPDONOR_API_ENDPOINT, parameters)
      .then(response => {
        return response.json();
      })
      .then(json => {
        return json;
      });
};