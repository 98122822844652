import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { patientFormSubmitAction } from '../actions/patientActions';
import { Col, FormGroup, Row } from 'react-bootstrap';

const moment = require('moment');
// Common styles object
const commonStyles = {
  input: {
    padding: '14px',
    fontSize: '18px',
    display: 'block',
    alignItems: 'center',
    borderRadius: '10px',
    border: '1px solid #D9D9D9',
    backgroundImage: 'none',
  },
  label: {
    color: '#2E2F35',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
};

class PatientForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstFocus: false,
      lastFocus: false,
      show: false,
      modalOKEnabled: false,
      accessMode: '',
      isSuccess: false,
      agreement: false,
      navigateToHomePage: false,
    };
    this.setShow = this.setShow.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    document.title = 'Liquid Love Patient';
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  componentDidMount() {
    loadCaptchaEnginge(6);
  }

  componentWillUnmount() {
    document.body.classList.remove('landing-page');
    document.body.classList.remove('sidebar-collapse');

    if (this.props.response.patient.response !== 'undefined') delete this.props.response.patient.response;

    this.props.response.patient.noServiceResponse = false;
    this.props.response.patient.systemRemarks = '';
  }

  setShow(bool) {
    this.setState({ show: bool });
  }
  handleClose = () => {
    this.setShow(false);
    this.state.navigateToHomePage = true;
  };

  handleShow = () => {
    this.setShow(true);
  };

  render() {
    if (this.state.navigateToHomePage) {
      this.state.navigateToHomePage = false;
      return <Navigate to="/liquidlove" replace={true} />
    }

    let message;
    message = 'Sairam! Processing your request';
    if (this.props.response.hasOwnProperty('patient') && this.props.response.patient.hasOwnProperty('response')) {
      this.state.modalOKEnabled = true;
      if (this.props.response.patient.noServiceResponse) {
        this.state.isSuccess = false;
        message = 'Sairam. Sorry! We are not able to save the details. Please try later.';
      } else if (this.props.response.patient.response.hasOwnProperty('error')) {
        this.state.isSuccess = false;
        if (this.props.response.patient.response.status === 400) message = this.props.response.patient.response.message;
        else message = 'Sairam. Sorry! We are not able to save the details. Please try later.';
      } else if (this.props.response.patient.response !== undefined) {
        this.state.isSuccess = true;
        message = 'Sairam! Our prayers to Swami for your speedy recovery. Please get a request letter from hospital.';
      }
    }

    let patientData;
    patientData = undefined;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          fullName: '',
          age: '',
          gender: 'male',
          bloodGroup: 'Any',
          requiredBy: '',
          units: '',
          hospitalName: '',
          address: '',
          pincode: '',
          attender: '',
          email: '',
          phone: '',
          purpose: 'Others',
          notes: '',
          userCaptcha: '',
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required('Required'),
          age: Yup.string()
            .required('Required')
            .matches(/^[1-9]+[0-9]*$/, 'Please provide a valid number without zero at start'),
          units: Yup.string()
            .required('Required')
            .matches(/^[1-9]+[0-9]*$/, 'Please provide a valid number without zero at start'),
          requiredBy: Yup.string()
            .required('Required')
            .test('requiredBy', 'Date format DD/MM/YYYY', (value) => {
              // Parse 'YYYY-MM-DD' to 'DD/MM/YYYY' for validation
              const formattedDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
              return moment(formattedDate, 'DD/MM/YYYY', true).isValid();
            }),
          attender: Yup.string().required('Required'),
          email: Yup.string().email('Email is invalid'),
          hospitalName: Yup.string().required('Required'),
          address: Yup.string().required('Required'),
          pincode: Yup.string()
            .required('Required')
            .matches(/^[1-9]+[0-9]*$/, 'Please provide a valid pincode with only numbers and without preceding zero')
            .length(6, 'Please enter 6 digits without any spaces'),
          phone: Yup.string()
            .required('Required')
            .matches(
              /^[1-9]+[0-9]*$/,
              'Please provide a valid mobile number with only numbers and without preceding zero'
            )
            .length(10, 'Please enter 10 digits without any spaces'),
          userCaptcha: Yup.string().test('userCaptcha', 'Invalid captcha', (value) => {
            return value && validateCaptcha(value, false);
          }),
        })}
        onSubmit={(fields) => {
          let id = patientData ? patientData.id : undefined;
          let fullname = fields.fullName;
          let age = fields.age;
          let gender = fields.gender;
          let email = fields.email;
          let bloodGroup = fields.bloodGroup;
          let requiredBy = moment(fields.requiredBy, 'YYYY-MM-DD').format('DD-MM-YYYY');
          let units = fields.units;
          let hospitalName = fields.hospitalName;
          let hospital = fields.address;
          let pincode = fields.pincode;
          let attender = fields.attender;
          let phone = fields.phone;
          let purpose = fields.purpose;
          let notes = fields.notes;
          let status = 'Open';

          const data = {
            id,
            fullname,
            age,
            gender,
            bloodGroup,
            requiredBy,
            units,
            hospitalName,
            hospital,
            pincode,
            attender,
            email,
            phone,
            purpose,
            status,
            notes,
          };

          const patientPayload = {
            data,
          };

          message = 'Sairam! Processing your request';
          this.handleShow();
          this.props.dispatch(patientFormSubmitAction(patientPayload));
        }}
        render={({ errors, status, touched }) => (
          <div class='card container form_background' style={{ borderRadius: '30px' }}>
            <h3 className='form_header'>Request Blood</h3>
            <Form>
              <FormGroup>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='name'
                      style={commonStyles.label}
                    >
                      Patients Name
                    </label>
                    <Field
                      name='fullName'
                      type='text'
                      placeholder='Enter Name'
                      className={'form-control' + (errors.fullName && touched.fullName ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='fullName'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='age'
                      style={commonStyles.label}
                    >
                      Patients Age
                    </label>
                    <Field
                      name='age'
                      placeholder='Enter Patients Age'
                      type='text'
                      className={'form-control' + (errors.age && touched.age ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='age'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='gender'
                      style={commonStyles.label}
                    >
                      Gender
                    </label>
                    <Field
                      className='form-control'
                      name='gender'
                      id='gender'
                      component='select'
                      style={commonStyles.input}
                    >
                      <option>Male</option>
                      <option>Female</option>
                    </Field>
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <Row>
                      <Col
                        md='7'
                        sm='12'
                        xs='12'
                      >
                        <label
                          for='bloodgroup'
                          style={commonStyles.label}
                        >
                          Blood Group
                        </label>
                        <Field
                          className='form-control'
                          name='bloodGroup'
                          id='bloodGroup'
                          component='select'
                          style={commonStyles.input}
                        >
                          <option>Any</option>
                          <option>O+</option>
                          <option>O-</option>
                          <option>A+</option>
                          <option>A-</option>
                          <option>B+</option>
                          <option>B-</option>
                          <option>AB+</option>
                          <option>AB-</option>
                          <option>A1+</option>
                          <option>A1-</option>
                          <option>A1B+</option>
                          <option>A1B-</option>
                          <option>A2+</option>
                          <option>A2-</option>
                          <option>A2B+</option>
                          <option>A2B-</option>
                          <option>Hh</option>
                        </Field>
                      </Col>
                      <Col
                        md='5'
                        sm='12'
                        xs='12'
                      >
                        <label
                          for='units'
                          style={commonStyles.label}
                        >
                          Units
                        </label>
                        <Field
                          name='units'
                          placeholder='Enter'
                          type='text'
                          className={'form-control' + (errors.units && touched.units ? ' is-invalid' : '')}
                          style={commonStyles.input}
                        />
                        <ErrorMessage
                          name='units'
                          component='div'
                          className='invalid-feedback'
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='requiredBy'
                      style={commonStyles.label}
                    >
                      Required By
                    </label>
                    <Field
                      name='requiredBy'
                      placeholder='DD/MM/YYYY'
                      type='date'
                      className={'form-control' + (errors.requiredBy && touched.requiredBy ? ' is-invalid' : '')}
                      style={{
                        ...commonStyles.input,
                        textTransform: 'uppercase',
                      }}
                    />
                    <ErrorMessage
                      name='requiredBy'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='hospitalName'
                      style={commonStyles.label}
                    >
                      Hospital or Blood bank Name
                    </label>
                    <Field
                      name='hospitalName'
                      placeholder='Enter Name'
                      type='text'
                      className={'form-control' + (errors.hospitalName && touched.hospitalName ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='hospitalName'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='address'
                      style={commonStyles.label}
                    >
                      Hospital Address
                    </label>
                    <Field
                      name='address'
                      placeholder='Enter Address'
                      type='text'
                      className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='address'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='pincode'
                      style={commonStyles.label}
                    >
                      Pincode
                    </label>
                    <Field
                      name='pincode'
                      placeholder='Enter Code'
                      type='text'
                      className={'form-control' + (errors.pincode && touched.pincode ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='pincode'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='attender'
                      style={commonStyles.label}
                    >
                      Patient's Attender
                    </label>
                    <Field
                      name='attender'
                      placeholder='Enter Name'
                      type='text'
                      className={'form-control' + (errors.attender && touched.attender ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='attender'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='phone'
                      style={commonStyles.label}
                    >
                      Mobile
                    </label>
                    <Field
                      name='phone'
                      placeholder='Enter Number'
                      type='text'
                      className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='phone'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='email'
                      style={commonStyles.label}
                    >
                      Email
                    </label>
                    <Field
                      name='email'
                      placeholder='Enter email'
                      type='text'
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='purpose'
                      style={commonStyles.label}
                    >
                      Purpose
                    </label>
                    <Field
                      className='form-control'
                      name='purpose'
                      id='purpose'
                      component='select'
                      style={commonStyles.input}
                    >
                      <option>Cancer treatment</option>
                      <option>Cardiac surgery</option>
                      <option>Dialysis</option>
                      <option>ENT or Eye related</option>
                      <option>Gastroenterology related</option>
                      <option>General weakness</option>
                      <option>Gynaecology related</option>
                      <option>Neuro surgery</option>
                      <option>New born delivery</option>
                      <option>Ortho surgery</option>
                      <option>Others</option>
                    </Field>
                  </Col>
                </Row>
                <Row className='align-items-center'>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4'
                  >
                    <label
                      for='notes'
                      style={commonStyles.label}
                    >
                      Notes
                    </label>
                    <Field
                      name='notes'
                      placeholder='Enter Notes'
                      type='textarea'
                      className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='notes'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                  <Col
                    md={{ span: 4, offset: 1 }}
                    sm='12'
                    xs='12'
                    className='mb-4 loadDivStyle'
                  >
                    <LoadCanvasTemplate reloadText='Reload' />

                    <Field
                      name='userCaptcha'
                      placeholder='Enter Captcha'
                      type='text'
                      className={'form-control' + (errors.userCaptcha && touched.userCaptcha ? ' is-invalid' : '')}
                      style={commonStyles.input}
                    />
                    <ErrorMessage
                      name='userCaptcha'
                      component='div'
                      className='invalid-feedback'
                    />
                  </Col>
                </Row>
              </FormGroup>
              <div className='buttons'>
                <button
                  type='reset'
                  className='btn btn-dark'
                  style={{ width: "200px", height: "50px" }}
                >
                  CANCEL
                </button>
                <button
                  type='submit'
                  className='btn btn-danger'
                  style={{ width: "200px", height: "50px" }}
                >
                  SUBMIT
                </button>
              </div>
              <Modal
                centered
                show={this.state.show}
                onHide={this.handleClose}
                backdrop='static'
                keyboard={false}
              >
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                  <button
                    disabled={!this.state.modalOKEnabled}
                    className='btn btn-dark'
                    onClick={this.handleClose}
                  >
                    OK
                  </button>
                </Modal.Footer>
              </Modal>
            </Form>
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(PatientForm);
