import * as types from './index';

export const donorFormSubmitAction = (donor) => {
  return {
    type: types.DONOR_SUBMIT,
    donor
  }
};

export const updateDonorAction = (donor) => {
  return {
    type: types.DONOR_UPDATE,
    donor
  }
};

export const selfserviceDonorAction = (donor) => {
  return {
    type: types.DONOR_SELFSERVICE,
    donor
  }
};

export const sendDonorOTPAction = (payload) => {
  return {
    type: types.SEND_OTP_DONOR,
    payload
  }
};