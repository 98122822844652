import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import alarmIcon from "../assets/images/alarm-clock.svg";
import medicalSupportIcon from "../assets/images/medical-support.svg";
import collage from "../assets/images/collage.jpg";
import worldIcon from "../assets/images/world.svg";
import pediatricsIcon from "../assets/images/pediatrics.svg";
import careIcon from "../assets/images/care.svg";

function DonateBlood() {
  const imageStyle = {
    width: "95%", // Make the image fill its container
    height: "auto",
  };
  const works = {
    card_style: {
      height: "180px",
      flexShrink: 0,
      boxShadow: "20px 20px 40px 0px rgba(255, 225, 225, 0.10)",
      backGround: "var(--white-900, #FFF)",
    },
    collage_text_style: {
      height: "160px",
      strokeWidth: "1px",
      stroke: "#FDC9C6",
    },
    icon: {
      width: "36px",
      height: "36px",
      flexShrink: 0,
    },
  };
  return (
    <Row>
      <Col md={8}>
        <div>
          <img src={collage} style={imageStyle} alt="SVG Image" />
        </div>
      </Col>
      <Col md={4}>
        <br />
        <Row md={12} style={works.collage_text_style}>
          <div className="col">
            <div>
              <img src={alarmIcon} style={works.icon} alt="SVG Image" />
              <h4 className="taglines"> Every 2 Seconds </h4>
              <p>Every year, 38,000 units of blood are required in India.</p>
            </div>
          </div>
          <hr
            className="mx-auto"
            style={{
              background: "#FF69B4",
              height: "1px",
              border: "none",
              width: "95%",
            }}
          />
        </Row>
        <Row md={12} style={works.collage_text_style}>
          <div className="col">
            <div className="">
              <img
                src={medicalSupportIcon}
                style={works.icon}
                alt="SVG Image"
              />
              <h4 className="taglines">1% Population Donates</h4>
              <p>Only 1% of the Indian population donates blood each year.</p>
            </div>
          </div>
          <hr
            className="mx-auto"
            style={{
              background: "#FF69B4",
              height: "1px",
              border: "none",
              width: "95%",
            }}
          />
        </Row>
        <Row md={12} style={works.collage_text_style}>
          <div className="col">
            <div className="">
              <img src={worldIcon} style={works.icon} alt="SVG Image" />
              <h4 className="taglines">14.6 Million Units</h4>
              <p>
                Every year, 14.6 million units of blood are required in India.
              </p>
            </div>
          </div>
          <hr
            className="mx-auto"
            style={{
              background: "#FF69B4",
              height: "1px",
              border: "none",
              width: "95%",
            }}
          />
        </Row>
        <Row md={12} style={works.collage_text_style}>
          <div className="col">
            <div className="">
              <img src={careIcon} style={works.icon} alt="SVG Image" />
              <h4 className="taglines">India Suffers</h4>
              <p>
                India suffers from an annual deficit of 2 million blood units.
              </p>
            </div>
          </div>
          <hr
            className="mx-auto"
            style={{
              background: "#FF69B4",
              height: "1px",
              border: "none",
              width: "95%",
            }}
          />
        </Row>
        <Row md={12} style={works.collage_text_style}>
          <div className="col">
            <div className="">
              <img src={pediatricsIcon} style={works.icon} alt="SVG Image" />
              <h4 className="taglines">Mother & Child</h4>
              <p>Vital for maternal care and child health.</p>
            </div>
          </div>
          <hr
            className="mx-auto"
            style={{
              background: "#FF69B4",
              height: "1px",
              border: "none",
              width: "95%",
            }}
          />
        </Row>
      </Col>
    </Row>
  );
}

export default DonateBlood;
