import React from "react";
import "./style.css"; // Adjust the path to your CSS file
import { Row, Col } from "react-bootstrap";
import bloofbullet from "../assets/images/bloodbullet.svg";
import handimg from "../assets/images/handwithblood.png";
import { useNavigate } from 'react-router-dom';

const Benefits = () => {
  const Benefits = {
    layout: {
      height: "auto",
      top: "1024px",
    },
    tittle_card: {
      width: "482px",
      color: "#2E2F35",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Abril Fatface",
      fontSize: "",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "110%",
    },
    tittle_description: {
      color: "#2E2F35",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "300",
      lineHeight: "150%",
      padding: "2% 0% 0% 0%",
    },
    tagline: {
      color: "#DE1717",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      padding: "10px",
    },
    icon: {
      width: "24px",
      height: "24px",
      flexShrink: "0",
    },
    handimg_style: {
      width: "100%",
      height: "100%",
      flexShrink: "0",
    },
    be_a_hero: {
      color: "#D30000",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Abril Fatface",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "100%"
    },
  };

  const navigate = useNavigate();

  const navigateToDonorPage = () => {  
    navigate('/liquidlove/donorRegistration');  
  };

  return (
    <div style={Benefits.layout}>
      <Row style={{ padding: "5%", backgroundColor: "white" }}>
        <Col lg={5}>
          <Row>
            <p className="Benefits_tittle_card" style={Benefits.tittle_card}>
              {" "}
              Benefits to the Blood Donor{" "}
            </p>
            <br />
            <p className="Benefits_section" style={Benefits.tittle_description}>
              {" "}
              The beauty of blood donation lies in its duality: as you give the
              gift of life, you also receive the gift of good health{" "}
            </p>
          </Row>
          <Row
            className="justifycenter"
            style={{ paddingTop: "10%", textAlign: "left" }}
          >
            <button onClick={navigateToDonorPage} type="button" style={{width:"auto"}} className="btn btn-default join-as-donor">
              Join as Donor
            </button>
            
          </Row>
        </Col>
        <Col lg={7}>
          <div className="row Benefits_bullets">
            <div style={Benefits.tagline}>
              <img
                style={{ paddingRight: "5%" }}
                src={bloofbullet}
                alt="bullet"
              />
              May reveal health problems
            </div>
            <hr
              className="mx-auto"
              style={{
                background: "#FF69B4",
                height: "2px",
                border: "none",
                width: "97%",
              }}
            />
            {/* <img style={{ paddingRight: "5%" }} src={line} alt="bullet" /> */}
          </div>
          <div className="row Benefits_bullets  ">
            <div style={Benefits.tagline}>
              <img
                style={{ paddingRight: "5%" }}
                src={bloofbullet}
                alt="bullet"
              />
              Maintains Cardio-vascular health
            </div>
            <hr
              className="mx-auto"
              style={{
                background: "#FF69B4",
                height: "2px",
                border: "none",
                width: "97%",
              }}
            />
            {/* <img style={{ paddingRight: "5%" }} src={line} alt="bullet" /> */}
          </div>
          <div className="row Benefits_bullets">
            <div style={Benefits.tagline}>
              <img
                style={{ paddingRight: "5%" }}
                src={bloofbullet}
                alt="bullet"
              />
              May reduce the risk of developing cancer
            </div>
            <hr
              className="mx-auto"
              style={{
                background: "#FF69B4",
                height: "2px",
                border: "none",
                width: "97%",
              }}
            />
            {/* <img style={{ paddingRight: "5%" }} src={line} alt="bullet" /> */}
          </div>
          <div className="row Benefits_bullets">
            <div style={Benefits.tagline}>
              <img
                style={{ paddingRight: "5%" }}
                src={bloofbullet}
                alt="bullet"
              />
              Prevents Hemochromatosis thereby maintains healthy liver
            </div>
            <hr
              className="mx-auto"
              style={{
                background: "#FF69B4",
                height: "2px",
                border: "none",
                width: "97%",
              }}
            />
            {/* <img style={{ paddingRight: "5%" }} src={line} alt="bullet" /> */}
          </div>
          <div className="row Benefits_bullets">
            <div style={Benefits.tagline}>
              <img
                style={{ paddingRight: "5%" }}
                src={bloofbullet}
                alt="bullet"
              />
              Helps your body’s detoxing potential
            </div>
            <hr
              className="mx-auto"
              style={{
                background: "#FF69B4",
                height: "2px",
                border: "none",
                width: "97%",
              }}
            />
            {/* <img style={{ paddingRight: "5%" }} src={line} alt="bullet" /> */}
          </div>
          <div className="row Benefits_bullets">
            <div style={Benefits.tagline}>
              <img
                style={{ paddingRight: "5%" }}
                src={bloofbullet}
                alt="bullet"
              />
              Help improve your mental state
            </div>
            <hr
              className="mx-auto"
              style={{
                background: "#FF69B4",
                height: "2px",
                border: "none",
                width: "97%",
              }}
            />
            {/* <img style={{ paddingRight: "5%" }} src={line} alt="bullet" /> */}
          </div>
          <div className="row Benefits_bullets">
            <div style={Benefits.tagline}>
              <img
                style={{ paddingRight: "5%" }}
                src={bloofbullet}
                alt="bullet"
              />
              Stimulates blood cell production
            </div>
            <hr
              className="mx-auto"
              style={{
                background: "#FF69B4",
                height: "2px",
                border: "none",
                width: "97%",
              }}
            />
            {/* <img style={{ paddingRight: "5%" }} src={line} alt="bullet" /> */}
          </div>
        </Col>
      </Row>
      <Row style={{ backgroundColor: "white" }}>
        <Col style={{ marginLeft: "30%" }}>
          <p className="be_a_hero" style={Benefits.be_a_hero}>
            Be a hero
          </p>
        </Col>
      </Row>
      <Row
        className="Benefits_section_description"
        style={{ backgroundColor: "#FDC9C6" }}
      >
        <Col style={{ width: "30%" }}>
          <div>
            <img
              className="hand_with_blood"
              src={handimg}
              alt="hand_with_blood"
            />
          </div>
        </Col>
        <Col
          
          style={{
            flex:2.5,
            width: "70%",
            paddingTop: "2%",
            paddingBottom: "2%",
            textAlign: "left",
          }}
        >
          <p style={Benefits.tittle_description}>
            Blood is a renewable resource; the body replenishes the donated
            blood within a few weeks, allowing donors to give multiple times a
            year.
          </p>
          <a href="/liquidlove/donorRegistration" className="ready_to_be_a_here">
            Ready to make a difference?
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default Benefits;
