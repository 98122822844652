import React, { Component } from "react";
import { connect } from "react-redux";
import "../assets/css/index.css";
import "../assets/css/Requestpage.css";
import ContactUs from "./ContactUs";
import DonateBlood from "./DonateBlood";
import Works from "./Works";
import { Col, Row } from "react-bootstrap";
import Banner from "./Banner";
import Benefits from "./Benefits";


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <Banner displayToggle="carousel" />
          <Works /> 
          <DonateBlood />
          <Benefits />
          <div style={{ paddingTop: window.innerWidth <= 767 ? "4%":'0' }}>
          <ContactUs />
          </div>
        </div>
          <Row >
          <Col md="12" sm="12" xs="12" style={{ display: window.innerWidth <= 768? 'none': 'block'}}>
            <svg
              width="100%"
              height="10"
              viewBox="0 0 1440 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 5H1440" stroke="#D30000" stroke-width="10" />
            </svg>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(App);
