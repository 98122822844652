import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputGroup from 'react-bootstrap/InputGroup';
import { sendDonorOTPAction } from '../actions/donorActions';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';


const moment = require('moment');
// Common styles object
const commonStyles = {
  input: {
    padding: '14px 30px 14px 14px',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    border: '1px solid #D9D9D9',
  },
  label: {
    color: '#2E2F35',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
};

class DonorLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstFocus: false,
      lastFocus: false,
      otp: '',
      message: '',
      phone: '',
      inputOTP: '',
      navigateToDonorUpdate: false,
      clearMessage: true,
      otpExpiresIn: '',
    };

    this.handleVerifyOTP = this.handleVerifyOTP.bind(this);
  }

  componentWillMount() {
    document.title = 'Donor Self-service';
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  componentWillUnmount() {
    this.state.message = '';
    this.state.clearMessage = true;
    this.state.otp = '';
    this.state.inputOTP = '';
    this.state.phone = '';
  }

  handleVerifyOTP = event => {
    var isOtpExpired = true;
    this.state.message = '';

    isOtpExpired = moment(new Date()).isAfter(moment(this.state.otpExpiresIn));

    if (!isOtpExpired &&
      this.state.otp === this.state.inputOTP &&
      this.state.otp !== '') {
      this.props.response.donor.isDonorVerified = true;
      this.props.response.donor.accessMode = 'self-service';
      this.state.navigateToDonorUpdate = true;
      this.forceUpdate();
    }
    else {
      this.state.message = 'Invalid or expired OTP. Click Get OTP.';
      this.state.clearMessage = false;
      this.forceUpdate();
    }
  }

  handleChangeOTP = (e) => this.setState({ inputOTP: e.target.value })

  render() {

    if (this.state.clearMessage)
      this.state.message = '';

    if (this.state.navigateToDonorUpdate) {
      this.state.navigateToDonorUpdate = false;
      this.state.message = '';
      this.state.clearMessage = true;
      this.state.otp = '';
      this.state.inputOTP = '';
      this.state.phone = '';
      return <Navigate to="/liquidlove/donor" replace={true} />
    }

    if (this.state.message !== 'Invalid or expired OTP. Click Send OTP.') //Verify OTP is not clicked
      if (this.props.response.donor === undefined || this.props.response.donor.noServiceResponse) {
        this.state.message = 'Sairam! Sorry. We are not able to process your request. Please try later.'
        this.state.clearMessage = false;
      }
      else if (this.props.response.donor.sendOTPDonor && this.props.response.donor.sendOTPDonor.hasOwnProperty('error')) {
        if (this.props.response.donor.sendOTPDonor.status === 400) {
          this.state.message = this.props.response.donor.sendOTPDonor.message;
          delete this.props.response.donor.sendOTPDonor;
          this.state.clearMessage = false;
        }
        else {
          this.state.message = 'Sairam! Sorry. We are not able to process your request.'
          delete this.props.response.donor.sendOTPDonor;
          this.state.clearMessage = false;
        }
      }
      else if (this.props.response.donor.sendOTPDonor && this.props.response.donor.sendOTPDonor.hasOwnProperty('status')) {
        if (this.props.response.donor.sendOTPDonor.status === 'success') {
          this.state.otp = this.props.response.donor.sendOTPDonor.donor.otp;
          this.state.otpExpiresIn = this.props.response.donor.sendOTPDonor.donor.otpExpiresIn;
          this.state.message = 'Sairam! OTP Sent to ' + this.props.response.donor.sendOTPDonor.donor.email;
          this.props.response.donor.donorUpdateOTP = this.props.response.donor.sendOTPDonor;
          delete this.props.response.donor.sendOTPDonor;
          this.state.clearMessage = false;
        }
        else if (this.props.response.donor.sendOTPDonor.status === 'error') {
          this.state.message = 'Sairam! Sorry. We are not able to process your request.'
          delete this.props.response.donor.sendOTPDonor;
          this.state.clearMessage = false;
        }
      }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          phone: '',
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string()
            .required('Required')
            .matches(
              /^[1-9]+[0-9]*$/,
              'Please provide a valid mobile number with only numbers and without preceding zero'
            )
            .length(10, 'Please enter 10 digits without any spaces'),
        })}
        onSubmit={(fields) => {
          let phone = fields.phone;

          const data = {
            phone,
          };

          this.state.message = '';
          if (this.props.response.donor)
            this.props.response.donor.noServiceResponse = false;

          if (this.props.response.donor.hasOwnProperty('sendOTPDonor'))
            this.props.response.donor.sendOTPDonor = undefined;

          this.props.response.donor.isDonorVerified = false;
          this.state.message = "Sairam! Processing your request"
          this.state.clearMessage = false;
          this.props.dispatch(sendDonorOTPAction(data));
          this.forceUpdate();
        }}

        render={({ errors, status, touched }) => (
          <Form>
            <div style={{ display: this.state.clearMessage ? "none" : "block" }}>{this.state.message}</div>
            <FormGroup>
              <Row>
                <Col
                  sm='12'
                  xs='12'
                  md='12'
                >
                  <label
                    for='phone'
                    style={commonStyles.label}
                  >
                    Mobile
                  </label>
                  <InputGroup>

                    <Col
                      sm='9'
                      xs='9'
                      md='9'
                      className='mb-4'
                    >
                      <Field
                        name='phone'
                        placeholder='Enter Number'
                        type='text'
                        className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                        style={{ ...commonStyles.input, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                      />
                      <ErrorMessage
                        name='phone'
                        component='div'
                        className='invalid-feedback'
                      />
                    </Col>
                    <Col
                      sm='3'
                      xs='3'
                      md='3'
                      className='mb-4'
                    >
                      <button
                        type="submit"
                        className='btn btn-danger'
                        style={{ width: "90px", height: "57px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      >Get OTP</button>
                    </Col>
                  </InputGroup>
                </Col>
              </Row>
              <label
                for='otp'
                style={commonStyles.label}
              >
                OTP
              </label>
              <InputGroup>
                <Field
                  name='otp'
                  onChange={this.handleChangeOTP} value={this.state.inputOTP}
                  placeholder='Enter OTP'
                  type='text'
                  className={'form-control' + (errors.otp && touched.otp ? ' is-invalid' : '')}
                  style={{ ...commonStyles.input, borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                />
                <ErrorMessage
                  name='otp'
                  component='div'
                  className='invalid-feedback'
                />
                <button onClick={this.handleVerifyOTP}
                  type="button"
                  className='btn btn-danger'
                  style={{ width: "90px", height: "57px" }}
                >Submit</button>
              </InputGroup>
            </FormGroup>
          </Form>
        )}
      />
    );
  }
}

const mapStateToProps = (response) => ({ response });

export default connect(mapStateToProps)(DonorLogin);
