import { takeLatest } from 'redux-saga/effects';

import { 
  donorSaga, 
  selfserviceDonorSaga, 
  updateDonorSaga,
  sendOTPDonorSaga
} from './donorSaga';

import { patientSaga, } from './patientSaga';
import { fetchLocalitySaga } from './localitySaga';  

import * as types from './../actions';

export function* watchDonor() {
  yield takeLatest(types.DONOR_SUBMIT, donorSaga);
}

export default function* watch() {
  yield takeLatest(types.PATIENT_SUBMIT, patientSaga);
  yield takeLatest(types.DONOR_SUBMIT, donorSaga);
  yield takeLatest(types.DONOR_UPDATE, updateDonorSaga);
  yield takeLatest(types.DONOR_SELFSERVICE, selfserviceDonorSaga);
  yield takeLatest(types.SEND_OTP_DONOR, sendOTPDonorSaga);
  yield takeLatest(types.LOCALITY_FETCH, fetchLocalitySaga);
}