import { put, call } from 'redux-saga/effects';
import { 
  donorService, 
  updateDonorService,
  selfserviceDonorService,
  fetchDonorByPhoneService,
  sendOTPDonorService  
 } from '../services/donorService';

import * as types from '../actions'

export function* donorSaga(payload) {
  try {
    const response = yield call(donorService, payload);
    yield put({ type: types.DONOR_SUBMIT_SUCCESS, response, });
  } catch(error) {
    yield put({ type: types.DONOR_SUBMIT_ERROR, error })
  }
}

export function* updateDonorSaga(payload) {
  try {
    const response = yield call(updateDonorService, payload);
    yield put({ type: types.DONOR_UPDATE_SUCCESS, response, });
  } catch(error) {
    yield put({ type: types.DONOR_UPDATE_ERROR, error })
  }
}

export function* selfserviceDonorSaga(payload) {
  try {
    const response = yield call(selfserviceDonorService, payload);
    yield put({ type: types.DONOR_SELFSERVICE_SUCCESS, response, });
  } catch(error) {
    yield put({ type: types.DONOR_SELFSERVICE_ERROR, error })
  }
}

export function* sendOTPDonorSaga(payload){
  try {
    const response = yield call(sendOTPDonorService, payload);
    yield put({ type: types.SEND_OTP_DONOR_SUCCESS, response, });
  } catch(error) {
    yield put({ type: types.SEND_OTP_DONOR_ERROR, error })
  }
}